
import Vue from "vue";
import MentorcastForm from "@/components/Mentorcast/MentorcastForm.vue";
import MentorcastPublish from "@/components/Mentorcast/MentorcastPublish.vue";
import MentorcastFormLoading from "@/components/Mentorcast/MentorcastFormLoading.vue";
import { mapGetters } from "vuex";
import moment from "moment-timezone";

export default Vue.extend({
  name: "MentorcastFormContainer",
  components: {
    MentorcastForm,
    MentorcastPublish,
    MentorcastFormLoading
  },
  props: {
    isPublished: Boolean,
    create: Boolean,
    profile: Object
  },
  data() {
    return {
      url: "",
      data: {
        title: "",
        image: "",
        duration: null,
        host_url: "",
        max_seats: "",
        seat_price: "",
        start_time: "",
        start_date: "",
        description: "",
        mentorcast_audience_id: "",
        mentoring_platform_id: "",
        invitation_password: "",
        language_id: "",
        categories: [] as string[],
        time_indicator: (this as any).$t("mentorcastHero.timeOptionAMLabel"),
        is_certificate: "",
        has_guest_users: 0
      } as any,
      isLicense: false,
      errors: {} as any,
      readyToPublish: false,
      isCreated: false,
      isAlreadyAgreed: this.isPublished,
      maxSeats: "",
      loading: false,
      skipOverview: !this.create
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency",
      languages: "helper/getLanguages",
      audiences: "helper/getMentorcastAudiences",
      platforms: "helper/getMentoringPlatforms",
      defaultConferencingPlatform: "helper/getDefaultConferencingPlatform",
      mentorcastSettings: "helper/getMentorcastSettings",
      skipMentorLicenseCastPopup:
        "mentorcast/getSkipMentorcastLicensePopupValue"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
    this.$store.dispatch("helper/GET_LANGUAGES");
    this.$store
      .dispatch("helper/GET_DEFAULT_CONFERENCING_PLATFORM")
      .then(() => {
        this.setDefaultPlatform();
      });
    this.$store.dispatch("helper/GET_MENTORING_PLATFORMS");
    this.$store.dispatch("mentorcast/GET_SKIP_MENTORCAST_LICENSE_POPUP");
    this.$store.dispatch("helper/GET_MENTORCAST_AUDIENCES");
    this.$store.dispatch("helper/GET_MENTORCAST_SETTINGS").then(settings => {
      if (this.isPublished) {
        return;
      }
      const keys = {
        seat_price: "paidMentorcast.defaults.seatPrice",
        duration: "paidMentorcast.defaults.duration",
        is_certificate: "paidMentorcast.defaults.certificate_id",
        mentorcast_audience_id: "paidMentorcast.defaults.audience_id",
        mentoring_platform_id: "paidMentorcast.defaults.platform_id",
        language_id: "paidMentorcast.defaults.language_id"
      } as any;

      for (const key of Object.keys(keys)) {
        const value = this.resolveDynamic(keys[key], settings);
        if (value != "" && value != null) {
          this.data[key] = value;
        }
      }
    });

    if (this.isPublished) {
      this.isLicense = true;
      this.loading = true;
      this.$store
        .dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id)
        .then(data => {
          this.readyToPublish = true;
          this.loading = false;
          if (!data.can_edit) {
            this.$router.push(`/mentorcasts/${data.uuid}`);
          }

          this.maxSeats = data.max_seats;

          for (const e of Object.keys(this.data)) {
            if (e == "start_time") {
              this.data.start_time = data["start_time_in_hour"];
            } else if (e !== "categories" && e in data) {
              this.data[e] = data[e];
            }
          }
          this.data.categories = data.categories.map((i: any) => i.title);
          this.url = data.image_link;
          this.data.start_date = moment(this.data.start_date)
            .format("DD-MM-YYYY")
            .toString();
        });
    }
  },
  methods: {
    setDefaultPlatform() {
      if (this.defaultConferencingPlatform) {
        this.data.mentoring_platform_id = this.defaultConferencingPlatform;
      }
    },
    resolveDynamic(path: any, obj: any) {
      return path.split(".").reduce(function(prev: any, curr: any) {
        return prev ? prev[curr] : null;
      }, obj || self);
    },
    update(data: any, url: string) {
      if (this.skipOverview) {
        (this as any).submtMentorcast(data);
        return;
      }

      //@ts-ignore
      this.validate(data).then(() => {
        this.data = { ...data };
        this.url = url;
        if (this.isLicense) {
          this.readyToPublish = true;
        } else {
          const id = this.platforms.find(
            (e: any) => e.name == "Hosted by Mentorice"
          )?.id;
          if (id === this.data.mentoring_platform_id) {
            this.readyToPublish = true;
          } else {
            (this as any).$refs["mentorcastForm"].showLicense();
          }
        }
      });
    },
    edit() {
      this.isAlreadyAgreed = false;
      this.readyToPublish = false;
      this.isCreated = true;
      //@ts-ignore
      if (this.$refs["mentorcastForm"]) {
        //@ts-ignore
        this.$refs["mentorcastForm"].setData();
      }
    },
    validate(data: any) {
      this.errors = {};
      let store = this.$store.dispatch("mentorcast/CREATE_MENTORCAST", {
        ...data,
        validation: true
      });
      if (this.isPublished) {
        store = this.$store.dispatch("mentorcast/UPDATE_MENTORCAST", {
          id: this.$route.params.id,
          formData: { ...data, validation: true }
        });
      }
      return new Promise(resolve => {
        store
          .then(() => {
            resolve(true);
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
          });
      });
    },
    setErrors(errors: any, isCategoryError = false) {
      if (isCategoryError) {
        this.errors.categories = errors.categories;
      } else {
        this.errors = errors;
      }
      this.readyToPublish = false;
      this.isCreated = true;
    },
    clearCategoryError() {
      if (this.errors.categories) {
        delete this.errors.categories;
      }
    },
    submtMentorcast(data = null) {
      this.$store
        .dispatch("mentorcast/UPDATE_MENTORCAST", {
          id: this.$route.params.id,
          formData: data ?? this.data
        })
        .then(id => {
          (this as any).$alertify.success(
            (this as any).$t("mentorcastHero.mentorcastUpdatedSuccessMessage")
          );
          this.$router.push(`/mentorcasts/${id}`);
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.$emit("errors", error.response.data.errors);
          }
        });
    }
  }
});
